<loading class="pos-fix vh-center"
         *ngIf="isLoading"></loading>
<div class="empty-hint centerA empty-icon tw-font-bold"
     *ngIf="noProductsInCart && !isLoading && !newCartUI">
    Your Cart is Empty
    <p class="tw-font-body tw-text-green-400 tw-text-300 tw-mt-4"
       (click)="goToShop()">
        Click here to check recommended products for you
    </p>
</div>
<!--old cart UI -->
<ng-container *ngIf="!newCartUI && !isLoading">
  <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
       (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-100"
       *ngIf="!noProductsInCart">
    <div class="tw-flex-1 tw-overflow-y-auto tw-flex tw-flex-col tw-pt-20">
      <div class="tw-flex tw-flex-col w70 tw-pl-6 tw-pb-4">
        <div class="font20 fw-700 float-left mainText tw-font-body"
             i18n="@@shoppingCart">
          Shopping cart
        </div>
        <div class="font14 fw-500 float-left subText tw-font-body"
             i18n="@@checkoutAllItems">
          Checkout {{totalProducts}} items
        </div>
      </div>
      <div *ngIf="!isLoading" class="tw-mt-0" [ngClass]="{ 'tw-pb-[206px]' : aovExp && amountRemaining && amountRemaining > 0 && !isLoading,
            'tw-pb-[136px]' : (!aovExp && !isLoading) || (aovExp && amountRemaining === 0 && !isLoading) }">
        <!--when AOV Experiment is false show below product cards of cart-->
        <ng-container *ngIf="!aovExp">
          <div class="tw-relative tw-flex tw-mx-[23px] tw-mb-[18px] tw-bg-[#ffffff] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-overflow-hidden tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
               *ngFor="let item of cartItems; let i = index"
               style="width: -webkit-fill-available; box-shadow: unset;">
            <img class="tw-w-2/5"
                 (click)="viewProductDetail(item)"
                 [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" />
            <span class="tw-ml-3 tw-m-4 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly"
                  [ngClass]="{ '!tw-mt-[30px]' : item?.isDoctorAdded }"
                  *ngIf="item?.price !== 0"
                  style="width: -webkit-fill-available;">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-2 tw-bg-gray-100 tw-h-6 tw-rounded-t-[25px] tw-rounded-r-[0px] tw-rounded-b-[0px] tw-rounded-l-[25px] tw-absolute tw-right-0 tw-top-2"
                             *ngIf="item?.isDoctorAdded">
                            <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full"
                                     [iconName]='"done"'></cs-icon>
                            <p class="tw-font-body tw-text-100 tw-text-blue-500 tw-h-6 tw-tracking-[0] tw-font-bold tw-text-[10px] tw-pt-1"
                               i18n="@@drSuggested">
                                Dr. Suggested
                            </p>
                        </div>
                        <p class="p1-new font12 tw-font-body">{{item?.title}}</p>
                        <p class="font12 tw-font-body">{{item?.quantityData}}</p>
                        <span>
                            <del class="pR5 fw-700 font14 tw-font-body">₹{{item?.mrp}}</del>
                            <span class="fw-700 clr-orange font14 tw-font-body">₹{{item?.price}}</span>
                        </span>
                        <span class="tw-mt-[13px] tw-h-8 flex">
                            <div
                              class="tw-flex tw-w-[93px] tw-h-[25px] tw-opacity-100 tw-justify-around tw-bg-[#FEFEFE] tw-border tw-border-solid tw-border-[#5FA653] tw-rounded-6 tw-text-[#5FA653] tw-mr-2">
                                <em class="cs-icon-minus green"
                                    (click)='removeProduct(item, "REMOVE", $event)'></em>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="!item.isRx">{{item?.quantity > nonRxLimit ? nonRxLimit :item?.quantity}}</b>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="item.isRx">{{item?.quantity > rxLimit ? rxLimit : item?.quantity}}</b>
                                <em class="cs-icon-plus green"
                                    (click)='addOrRemove(item, "ADD", $event)'></em>
                            </div>
                            <p class="font12 tw-font-body"
                               i18n="@@remove"
                               (click)='removeProduct(item, "DELETE", $event)'
                               style="margin: unset; margin-right: 17px;">
                                Remove
                            </p>
                        </span>
                    </span>
                </div>
            </ng-container><ng-container *ngIf="aovExp">
                <div class="align-center tw-relative tw-flex tw-mx-[23px] tw-mb-[18px] tw-bg-[#ffffff] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-overflow-hidden tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
                     *ngFor='let item of ( (this.cart.get("totalPrice") >= this.maxPrice.totalValue &amp;&amp; showFreeProd &amp;&amp; !noFreeProduct) ? (cartItems | slice: 0: cartItems.length - 1) : (cartItems) ); let i = index'
                     style="width: -webkit-fill-available; box-shadow: unset;">
                    <img class="tw-mr-[5px] tw-object-contain tw-rounded-tl-5 tw-rounded-br-5 tw-w-2/5"
                         (click)="viewProductDetail(item)"
                         [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" /><span
                          class="tw-ml-3 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly tw-m-4"
                          [ngClass]="{ '!tw-mt-[30px]' : item?.isDoctorAdded }"
                          *ngIf="item?.price !== 0">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-2 tw-bg-green-100 tw-absolute tw-right-0 tw-top-2"
                             *ngIf="item?.isDoctorAdded">
                            <img src="/assets/images/correct.png" />
                            <p class="tw-font-body tw-text-black">Dr. Recommended</p>
                        </div>
                        <p class="p1-new font12 tw-font-body">{{item?.title}}</p>
                        <p class="font12 tw-font-body">{{item?.quantityData}}</p>
                        <span><del class="pR5 fw-700 font14 tw-font-body">₹{{item?.mrp}}</del><span
                                  class="fw-700 clr-orange font14 tw-font-body">₹{{item?.price}}</span></span>
                        <span class="tw-mt-[13px] tw-h-8 flex tw-gap-3">
                            <div
                                 class="tw-flex tw-w-[93px] tw-h-[25px] tw-opacity-100 tw-justify-around tw-bg-[#FEFEFE] tw-border tw-border-solid tw-border-[#5FA653] tw-rounded-6 tw-text-[#5FA653] tw-mr-2">
                                <em class="cs-icon-minus green"
                                    (click)='removeProduct(item, "REMOVE", $event)'></em>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="!item.isRx">{{item?.quantity > nonRxLimit ? nonRxLimit :item?.quantity}}</b>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="item.isRx">{{item?.quantity > rxLimit ? rxLimit : item?.quantity}}</b><em
                                    class="cs-icon-plus green"
                                    (click)='addOrRemove(item, "ADD", $event)'></em>
                            </div>
                            <p class="font12"
                               i18n="@@remove"
                               (click)='removeProduct(item, "DELETE", $event)'
                               style="margin: unset">
                                Remove
                            </p>
                        </span>
                    </span>
                </div>
                <div class="tw-flex tw-mx-[23px] tw-mb-[18px] tw-overflow-hidden tw-bg-[#FFF0F0] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-shadow-z2 tw-opacity-100 tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
                     *ngIf="aovExp &amp;&amp; this.cart.get('totalPrice') >= this.maxPrice.totalValue"
                     style="width: -webkit-fill-available; box-shadow: unset;">
                    <img class="tw-mr-[5px] tw-object-contain tw-rounded-tl-5 tw-rounded-br-5 tw-w-2/5"
                         [src]="freeProduct.rebrandedImageWithBackground || freeProduct.rebrandedImage || freeProduct.rebrandedImageWithoutBackground || freeProduct.imageURL" />
                    <span class="tw-ml-3 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly m16">
                        <p class="p1-new font12 tw-font-body">{{freeProduct?.title}}</p>
                        <p class="font12 tw-font-body">
                            {{freeProduct?.quantity}} {{freeProduct?.quantityUnit}}
                        </p>
                        <span class="tw-justify-between tw-mt-[13px] tw-h-8 flex">
                            <p class="tw-text-red-400 font14 fw-700 clr-orange tw-font-body tw-uppercase"
                               i18n="@@free">free</p>
                        </span>
                    </span>
                </div>
            </ng-container>
        </div>

        <!-- <div class="tw-flex-1"></div> -->
    </div>
<!--    old AOV strip when amount is remaining to avail free product-->
    <div class="tw-px-4 tw-py-2 tw-mb-[86px] tw-fixed tw-right-0 tw-left-0 tw-bottom-0"
    style="backdrop-filter: blur(5px); background-color: rgba(255, 255, 255, 0.8) !important;"
         *ngIf="cartItems?.length &amp;&amp; !isLoading">
        <div class="tw-bg-[#FAFAFA] tw-bg-[position:0_0] tw-p-2 tw-bg-no-repeat tw-opacity-100 tw-flex tw-flex-wrap tw-justify-between tw-items-center"
         *ngIf="aovExp && amountRemaining && amountRemaining > 0 && !isLoading">
            <div class="tw-pt-[10px]">
                <div class="font14 tw-font-body"
                    i18n="@@shopMore">
                    Shop for ₹{{ amountRemaining%1 != 0 ? amountRemaining.toFixed(2) :
                    amountRemaining }} more to
                </div>
                <span class="tw-inline-flex font14 tw-font-body"
                    i18n="@@getAFreeGift">
                    <p class="tw-mr-[0.2em] tw-font-bold"
                    style="margin-top: unset; margin-bottom: unset;">Get a</p>
                    <p class="tw-text-red-400 tw-font-semibold tw-mr-[0.2em]"
                    style="margin-top: unset; margin-bottom: unset;">free</p>
                    <p class="tw-mr-[0.2em] tw-font-bold"
                    style="margin-top: unset; margin-bottom: unset;">product</p>
                </span>
            </div>
            <div class="rightBlock">
                <p class="tw-text-center tw-not-italic tw-font-bold tw-text-[13px] tw-leading-[33px] tw-tracking-[0px] tw-text-[#5EAC73] tw-opacity-100 before:tw-mr-[2px] green tw-font-body tw-normal-case tw-text-green-400 tw-text-200"
                (click)="goToList()"
                i18n="@@addMoreProducts">
                    Add more products
                </p>
            </div>
        </div>

         <div>
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@subtotal">Subtotal</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums">₹{{totalMRP}}</span>
        </div>
        <div>
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@discount">Discount</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums"
                  *ngIf="!showCashBalance">- ₹{{totalMRP - totalSP}}</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums"
                  *ngIf="showCashBalance">- ₹{{totalMRP - (totalSP + cashBalance)}}</span>
        </div>
        <div class="tw-flex"
             *ngIf="showCashBalance">
            <span class="tw-w-3/5 tw-flex"><span class="tw-font-body tw-text-200 tw-text-gray-500"
                      i18n="@@cureskinCash">Cureskin Cash</span><img class="tw-w-4 tw-h-4 tw-ml-1"
                     src="/assets/images/cureskin-cash-icon.png" /></span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums">-
                ₹{{cashBalance}}</span>
        </div>
        <div class="tw-mb-3">
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@delivery">Delivery</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200"><span
                      class="tw-text-orange-400 tw-tracking-normal tw-font-bold"
                      i18n="@@free">free</span><del class="tw-tabular-nums"> ₹{{100}}</del></span>
        </div>
        <div class="tw-border-b tw-border-gray-300 tw-mb-3"></div>
        <div>
            <span class="tw-w-3/5 tw-font-body tw-font-bold tw-text-300"
                  i18n="@@grandTotal">Grand Total</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-300 tw-font-bold">₹{{totalSP}}</span>
        </div>
    </div>
    <div class="tw-flex-none tw-w-full tw-flex tw-flex-row tw-flex-nowrap tw-text-center tw-font-medium tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-bg-[#EBF1CE] tw-justify-between tw-py-4"
         *ngIf="!isLoading">
        <button class="tw-border-none tw-w-full tw-h-[54px] tw-relative tw-text-[#ffffff] tw-text-[18px] tw-capitalize tw-overflow-visible w100 p15 shine loop tw-bg-green-400"
                *ngIf="discount > 0"
                (click)="checkout()">
            <div class="tw-absolute tw-flex tw-items-center tw-justify-center tw-top-[-15px] tw-left-[10px] tw-text-orange-400"
                 *ngIf="discount > 5">
                <svg class="tw-w-[85px] tw-h-[85px] tw-relative tw-anima orange-spin"
                     xmlns="http://www.w3.org/2000/svg"
                     width="64.443"
                     height="67.447"
                     viewBox="0 0 64.443 67.447"
                     style="animation: spin 4s linear infinite;">
                    <path id="Path_23051"
                          data-name="Path 23051"
                          d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z"
                          transform="translate(-23.961 -186.363)"
                          fill="#D66736"></path>
                </svg>
              <span class="tw-absolute tw-text-[22px] tw-text-white tw-uppercase tw-z-20 tw-leading-none tw-font-bold tw-font-body">{{discount}}%<br />off</span>
            </div>
            <span class="tw-font-bold btn-txt tw-font-body tw-normal-case tw-ml-2"
                  i18n="@@proceedToBuy" [ngStyle]="{ 'font-size' : user.get('languagePreference') === 'mr' || user.get('languagePreference') === 'kn' ?  '13px' : '18px'}">Proceed to Buy</span>
        </button>
        <button class="tw-border-none tw-w-full tw-h-[54px] tw-relative tw-text-[#ffffff] tw-text-[18px] tw-capitalize tw-overflow-visible w100 p15 shine loop tw-bg-green-400" *ngIf="discount <= 0" (click)="checkout()">
            <span class="tw-font-bold btn-txt tw-font-body tw-normal-case tw-text-500 tw-ml-2" i18n="@@proceedToBuy">Proceed to Buy</span><span>&nbsp;&#64;₹{{totalSP}}</span>
        </button>
    </div>
  </div>
</ng-container>
<!--new cart UI -->
<ng-container *ngIf="newCartUI && !isLoading">
  <!-- new header-->
  <header class="tw-sticky tw-top-0 tw-z-40">
    <div class=" tw-flex tw-justify-between tw-items-end tw-bg-white tw-py-2 tw-pb-[10px] tw-px-[27px] tw-relative tw-h-[60px] tw-shadow-z4">
      <div class="tw-w-[16px] tw-aspect-square tw-flex tw-justify-center tw-items-center tw-absolute"  (click)="back()">
        <cs-icon class="tw-h-full tw-w-full tw-text-white"
                 [iconName]="'back_new_icon'"></cs-icon>
      </div>
      <div class="tw-flex-1 tw-flex tw-justify-center tw-items-center">
        <h1 class="tw-text-center tw-text-300 tw-font-semibold tw-w-full tw-capitalize tw-font-body" i18n="@@yourCart">your cart</h1>
      </div>
    </div>
  </header>
  <div id="newCartUIContainer" class="tw-bg-gray-100 tw-h-screen tw-overflow-y-scroll tw-font-body tw-relative" [ngClass]="{'tw-pb-[120px]': amountRemaining > 0, 'tw-pb-[60px]': amountRemaining === 0 || !aovExp}">
<!--    main content of cart page-->
    <div *ngIf="!isLoading" class="tw-pt-4 tw-pb-[180px]">
<!--      no products UI-->
      <section *ngIf="noProductsInCart" class="tw-w-full tw-pt-0 tw-p-4 tw-pb-6">
        <div class="tw-h-[232px] tw-bg-white tw-rounded-2 tw-flex tw-flex-col tw-justify-center tw-items-center">
          <img class="tw-h-[96px] tw-aspect-square tw-rounded-full tw-mb-4"
               src="/assets/icons/empty-cart-icon.svg" alt="empty cart"/>
          <h1 class="tw-text-200 tw-font-bold tw-mb-2 tw-text-gray-800" i18n="@@yourCartIsEmpty">Your Cart is Empty</h1>
          <div class="tw-bg-teal-600 tw-text-300 tw-text-white tw-h-10 tw-w-[200px] tw-font-bold tw-rounded-2 tw-flex tw-justify-center tw-items-center" (click)="goToShop()" i18n="@@startShopping">Start Shopping</div>
        </div>
      </section>
<!--      UI with products in cart-->
    <section *ngIf="!noProductsInCart" class="tw-bg-white tw-py-2 tw-rounded-2 tw-shadow-z4 tw-mb-8 tw-mx-4">
  <!--    total products in cart container-->
      <div class="tw-px-2">
        <div class="tw-rounded-t-2 cart-page-bg-gradient tw-p-2 tw-flex tw-justify-between tw-items-center tw-h-[48px]">
          <h1 class="tw-text-300 tw-font-bold">
            <span>{{totalProducts}}&nbsp;</span>
            <span i18n="@@products">Products</span>
          </h1>
          <div *ngIf="showSavedText" class="tw-text-teal-600 tw-flex tw-justify-end tw-items-center tw-gap-[2px]">
            <div class="tw-relative tw-mr-[2px]">
              <img class="tw-h-[16px] tw-w-[16px] tw-rounded-full tw-shrink-0 tw-block tw-animate-spin_slow"
                   src="/assets/icons/percentageBgSavings.svg" alt="discount percentage off bg"/>
                <img class="tw-absolute tw-inset-0 tw-w-[5.98px] tw-h-[7.99px] tw-shrink-0 tw-top-1 mx-auto"
                     src="/assets/icons/percentageSymbol.svg" alt="discount percentage off bg"/>
            </div>
            <h3 class="tw-text-200 tw-font-medium tw-mt-[1px]" i18n="@@saved">SAVED</h3>
            <h3 class="tw-text-300 tw-font-bold">₹{{ totalMRP - (totalSP + (cashBalance ?? 0)) + (cashBalance ?? 0) }}</h3>
          </div>
        </div>
      </div>
      <!--  free products container-->
      <div *ngFor="let item of freeProductsArray; let i = index" class="tw-py-2 tw-flex tw-border-b tw-border-b-gray-100" [ngClass]="{'tw-hidden': item?.price}">
        <div class="tw-p-[8px] tw-pt-[8px] tw-pr-4 tw-pb-2 tw-pl-[12px] tw-flex-shrink-0">
          <div class="tw-relative tw-rounded-1 tw-overflow-hidden">
            <img class="tw-w-[78px] tw-rounded-1 tw-overflow-hidden tw-object-cover"
                 (click)="viewProductDetail(item)"
                 [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" />
            <div class="tw-absolute tw-bottom-0 tw-right-0 tw-left-0">
              <div class="tw-flex tw-justify-center tw-items-center tw-gap-[2px] tw-bg-orange-100 tw-text-orange-400"
                   *ngIf="item?.isDoctorAdded">
                <cs-icon class="tw-h-2 tw-w-2 tw-text-orange-400 tw-bg-transparent tw-rounded-full tw-shrink-0"
                         [iconName]='"thickTickIcon"'></cs-icon>
                <p class="tw-font-body tw-text-orange-400 tw-text-50 tw-font-medium tw-whitespace-nowrap" i18n="@@drAdded">
                  Dr. Added
                </p>
              </div>
            </div>
            <div class="tw-absolute tw-top-0 tw-left-0 tw-w-[34px] tw-h-[34px]">
              <img class="w-full h-full"
                   src="/assets/icons/free-gift-bg.svg" alt="free-gift"/>
            </div>
          </div>
        </div>
        <div class="tw-py-2 tw-pr-2 tw-w-full">
          <div class="flex tw-items-start tw-justify-between tw-pt-3 tw-mb-[14px]">
            <h1 class="tw-text-200">{{item?.title}}</h1>
          </div>

          <div>
              <p class="tw-text-gray-400 tw-text-100 tw-font-medium">
                <span>{{item?.quantity}}</span>
                <span>unit</span>
              </p>
            <div class="tw-flex tw-justify-between">
              <div class="tw-w-max">
                <div class="tw-flex tw-flex-col xs:tw-flex-row-reverse xs:tw-items-center tw-gap-1 tw-font-body tw-mt-1">
                  <span class="tw-text-500 tw-font-bold tw-text-orange-500 tw-block tw-leading-[16px] tw-font-body tw-uppercase" i18n="@@free">free</span>
                  <del class="tw-text-200 tw-text-gray-500 tw-block tw-font-body">₹{{ item.mrp }}</del>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <!--   non free products container-->
      <div *ngFor="let item of cartItems.slice().reverse(); let i = index" class="tw-py-2 tw-flex tw-h-[128px]"
           [ngClass]="{
                      'tw-border-b tw-border-b-gray-100': cartItems.length !== i + (aovExp ? freeProductsArray.length ? freeProductsArray.length: freeProductsArray.length  + 1 : 1),
                      'tw-hidden': !item?.price
  }">
        <div class="tw-p-[8px] tw-pt-[8px] tw-pr-4 tw-pb-2 tw-pl-[12px] tw-flex-shrink-0">
          <div class="tw-relative tw-rounded-1 tw-overflow-hidden">
            <img class="tw-w-[78px] tw-rounded-1 tw-overflow-hidden tw-object-cover"
                 (click)="viewProductDetail(item)"
                 [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" />
            <div class="tw-absolute tw-bottom-0 tw-right-0 tw-left-0">
              <div class="tw-flex tw-justify-center tw-items-center tw-gap-[2px] tw-bg-orange-100 tw-text-orange-400"
                   *ngIf="item?.isDoctorAdded">
                <cs-icon class="tw-h-2 tw-w-2 tw-text-orange-400 tw-bg-transparent tw-rounded-full tw-shrink-0"
                         [iconName]='"thickTickIcon"'></cs-icon>
                <p class="tw-font-body tw-text-orange-400 tw-text-50 tw-font-medium tw-whitespace-nowrap" i18n="@@drAdded">
                  Dr. Added
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-pr-4 tw-w-full tw-flex tw-flex-col tw-justify-between tw-py-1 tw-pb-2">
          <div class="tw-flex tw-items-start tw-justify-between tw-pt-[7px]">
            <h1 class="tw-text-200 tw-w-full tw-max-w-[75%]">{{item?.title}}</h1>
            <div class="tw-shrink-0">
              <cs-icon (click)='removeProduct(item, "DELETE", $event)' class="tw-text-white tw-h-4 tw-translate-y-0 tw-mr-[-4px]"
                       [iconName]="'delete_icon_product_card'"></cs-icon>
            </div>
          </div>

          <div class="">
            <div class="tw-mb-2">
              <p class="tw-text-gray-400 tw-text-100 tw-font-medium">{{item?.quantityData}}</p>
            </div>
            <div class="tw-flex tw-justify-between tw-pb-[4.2px]">
              <div class="tw-h-6 tw-w-[78px] tw-border tw-border-teal-600 tw-rounded-[6px] tw-flex tw-justify-between tw-items-center tw-px-2 tw-text-teal-600 tw-text-200 tw-font-bold tw-font-body">
                <span class="font-plus-minus" (click)='removeProduct(item, "REMOVE", $event)'>-</span>
                <span>{{ item?.quantity }}</span>
                <span class="font-plus-minus" (click)='addOrRemove(item, "ADD", $event)'>+</span>
              </div>
              <div class="tw-w-max tw-mt-1 -tw-mr-1">
                <div class="tw-flex tw-flex-col xs:tw-flex-row-reverse xs:tw-items-center tw-gap-1 tw-font-body" *ngIf="item.mrp !== item.price">
                  <span class="tw-text-400 tw-font-bold tw-text-orange-500 tw-block tw-leading-[16px] tw-font-body">₹{{ item.price }}</span>
                  <del class="tw-text-200 tw-text-gray-500 tw-block tw-font-body">₹{{ item.mrp }}</del>
                </div>
                <div *ngIf="item.mrp === item.price">
                  <span class="tw-text-400 tw-font-bold tw-text-orange-500 tw-block tw-leading-[16px] tw-font-body">₹{{ item.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--      products you may need -->
    <section *ngIf="cart?.productsYouMayNeed?.length" class="tw-h-[192px] cart-page-products-you-may-need-gradient tw-mb-8 tw-p-4 tw-pt-[19px] tw-px-0">
      <h1 class="tw-font-bold tw-text-300  tw-font-body tw-pb-[11px] tw-px-[18px]" i18n="@@productsYouMayNeed">Products You May Need</h1>
      <div class="tw-h-[128px] tw-flex tw-gap-2 tw-overflow-x-auto tw-px-[16px]">
        <ng-container *ngFor="let item of cart?.productsYouMayNeed; let i = index">
          <div class="tw-py-3 tw-px-2 tw-w-[252px] tw-h-[120px] tw-bg-white tw-shadow-z4 tw-gap-[6.5px] tw-shrink-0 tw-rounded-2 tw-flex">
            <img
              (click)="viewProductDetail(item)"
              [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL"
              class="tw-w-[78px] tw-shrink-0 tw-rounded-1 tw-object-cover"
            />
            <div class="tw-flex tw-flex-col tw-flex-1 tw-justify-between">
             <div>
               <h2 *ngIf="item?.tag" class="cart-page-tag-bg-gradient tw-h-4 tw-w-[78px] tw-text-50 tw-pl-[5px] tw-mt-[-2px] tw-font-medium tw-rounded-1 tw-mb-1">{{item?.tag}}</h2>
               <h1 class="tw-text-gray-800 tw-text-200">{{item?.title}}</h1>
             </div>
              <div class="tw-flex tw-justify-between tw-items-center">
                <div>
                  <span class="tw-text-400 tw-font-bold tw-block tw-leading-[16px] tw-font-body tw-text-orange-500 tw-mt-[1px]">₹{{ item.price }}</span>
                  <del class="tw-text-gray-400 tw-block tw-font-body tw-text-200 ">₹{{ item.mrp }}</del>
                </div>
                <div class="tw-bg-teal-600 tw-w-[80px] tw-h-8 tw-text-white tw-text-300 tw-font-bold tw-flex tw-justify-center tw-items-center tw-rounded-3 tw-mb-[2px]" (click)='addSingleProductToCart(item?.productId)' i18n="@@add">Add</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  <!--  bill details container-->
    <section *ngIf="!noProductsInCart" #billDetailsContainer class="tw-rounded-2 tw-shadow-z4 tw-bg-white tw-pt-5 tw-mx-4">
  <!--    section title-->
      <div class="tw-flex tw-gap-[6px] tw-relative tw-pb-3 tw-px-4">
        <cs-icon class="tw-h-5 tw-aspect-square tw-text-white"
                 [iconName]="'bill_icon'"></cs-icon>
        <h1 class="tw-text-300 tw-font-bold" i18n="@@billDetails">Bill Details</h1>
        <div class="tw-absolute tw-w-[90%] tw-border-b-[1px] tw-border-dashed tw-border-gray-200 tw-left-1/2 tw-bottom-0 tw-transform tw-translate-y-[1px] -tw-translate-x-1/2"></div>
      </div>
  <!--    details container-->
      <div class="tw-font-body tw-text-200 tw-pt-[2px]">
  <!--      sub details container-->
        <div class="tw-py-3 tw-relative tw-px-4">
          <!--      subtotal-->
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <span class="tw-text-gray-800" i18n="@@subtotal">Subtotal</span>
            <span class="tw-tabular-nums">₹{{totalMRP}}</span>
          </div>
          <!--      Discount-->
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <span class="tw-text-gray-800" i18n="@@discount">Discount</span>
            <div class="tw-flex tw-items-center tw-text-orange-500">
              <span class="tw-tracking-[1px] tw-tabular-nums" *ngIf="!showCashBalance">- ₹{{totalMRP - totalSP}}</span>
              <span class="tw-tracking-[1px] tw-tabular-nums" *ngIf="showCashBalance">- ₹{{totalMRP - (totalSP + cashBalance)}}</span>
            </div>
          </div>
          <!--      Cureskin Cash-->
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2"
               *ngIf="showCashBalance">
              <span class="tw-flex">
                <span class="tw-text-gray-800" i18n="@@cureskinCash">Cureskin Cash</span>
                <img class="tw-w-4 tw-h-4 tw-ml-1" src="/assets/images/cureskin-cash-icon.png" />
              </span>
            <span class="tw-tracking-[1px] tw-tabular-nums tw-text-orange-500">-
                  ₹{{cashBalance}}</span>
          </div>
          <!--      Delivery-->
          <div class="tw-flex tw-justify-between tw-items-center">
            <span class="tw-text-gray-800" i18n="@@delivery">Delivery</span>
            <span class="tw-tracking-[1px] tw-flex tw-gap-1">
            <del class="tw-tabular-nums"> ₹{{100}}</del>
            <span class="tw-text-orange-400 tw-tracking-normal tw-uppercase" i18n="@@free">free</span>
          </span>
          </div>
          <div class="tw-absolute tw-w-[90%] tw-border-b-[1px] tw-border-dashed tw-border-gray-200 tw-left-1/2 tw-bottom-0 tw-transform -tw-translate-x-1/2"></div>
        </div>
  <!--      Total container-->
        <div>
  <!--        total payable-->
          <div class="tw-py-3 tw-pb-4 tw-text-300 tw-font-semibold tw-flex tw-justify-between tw-items-center tw-px-4">
            <h1 i18n="@@totalPayable">Total Payable</h1>
            <span class="tw-font-bold">₹{{totalSP}}</span>
          </div>
  <!--        you are saving container-->
          <div class="cart-page-bill-savings-gradient tw-h-8 tw-rounded-b-2 tw-flex tw-justify-center tw-items-center">
            <p class="tw-flex tw-pl-[2px]">
              <span class="tw-relative tw-mr-[8px]">
                <img class="tw-h-[16px] tw-w-[16px] tw-rounded-full tw-shrink-0 tw-block tw-animate-spin_slow"
                     src="/assets/icons/percentageBgSavings.svg" alt="discount percentage off bg"/>
                <img class="tw-absolute tw-inset-0 tw-w-[5.98px] tw-h-[7.99px] tw-shrink-0 tw-top-1 mx-auto"
                     src="/assets/icons/percentageSymbol.svg" alt="discount percentage off bg"/>
              </span>
              <span class="">You are saving</span>
              &nbsp;
              <span class="tw-text-teal-600 tw-font-bold">₹{{ totalMRP - (totalSP + (cashBalance ?? 0)) + (cashBalance ?? 0) }}</span>
              &nbsp;
              <span>on this order</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
    <!--        bottom sticky container-->
    <section id="bottomStickyContainer" *ngIf="!isLoading && !noProductsInCart" class="tw-fixed tw-bottom-0 tw-w-full tw-shadow-z8 tw-rounded-t-3">
<!--      AOV remaining banner-->
      <div *ngIf="amountRemaining > 0" class="tw-h-[72px] tw-w-full tw-rounded-t-3 tw-relative tw-bg-white">
          <img class="tw-absolute tw-w-full tw-h-[72px] tw-object-cover tw-opacity-50 tw-rounded-t-3 tw-overflow-hidden"
               src="/assets/gifs/aov-banner-bg.gif" alt="aov banner bg"/>
        <div class=" tw-flex tw-gap-6 tw-pl-[19.96px] tw-rounded-t-3 tw-inset-0 tw-absolute">
          <div>
            <img alt="free-product" [src]="freeProductBannerImageForAov" class="tw-w-18 tw-aspect-square tw-object-contain tw-bg-white tw-p-2 tw-rounded-full tw-border-[3px] tw-border-orange-50 -tw-translate-y-[10.26px]"/>
          </div>
          <div class="tw-font-body tw-py-[10.26px]">
            <h1 class="tw-font-bold tw-text-400 tw-mb-[7px]" i18n="@@getAFreePouch">
              Get a <span class="tw-font-bold tw-italic">free</span> pouch
            </h1>
            <div class="tw-h-[4px] tw-w-[182px] tw-rounded-full tw-bg-yellow-50 tw-overflow-hidden tw-mb-[5.74px]">
              <div class="tw-bg-orange-600 tw-h-full tw-transition-all tw-rounded-full" [style.width.%]="aovBannerPercentageDone"></div>
            </div>
            <p class="tw-text-200">
              Shop for
<!--              <span class="tw-font-bold">₹{{ amountRemaining%1 != 0 ? amountRemaining.toFixed(2) :-->
<!--                amountRemaining }}</span>-->
              <span class="tw-font-bold">₹{{ maxPrice.totalValue - totalSP }}</span>
              more
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="!showViewDetailedButtonContainer && (aovExp ? amountRemaining === 0 : true)" class="tw-flex tw-justify-between tw-items-center tw-p-[7px] tw-px-4 tw-h-8 tw-bg-teal-100 tw-rounded-t-3">
        <h3 *ngIf="!freeProductsArray.length" class="tw-uppercase tw-text-100 tw-tracking-[1.7px]" i18n="@@freeDelivery">Free Delivery</h3>
        <h3 *ngIf="freeProductsArray.length &&  amountRemaining === 0" class="tw-uppercase tw-text-100 tw-tracking-[1.7px]" i18n="@@freePouchAOV">Free Pouch</h3>
        <div (click)="scrollToBottom()" class="tw-text-teal-600 tw-text-200 tw-font-bold flex tw-justify-end tw-items-center">
          <span i18n="@@viewDetailedBill">View Detailed Bill</span>
          <img src="/assets/icons/forward-right-chevron.svg" class="tw-w-6 tw-aspect-square">
          </div>
      </div>
      <div class="tw-bg-white tw-w-full tw-px-4 tw-py-6 tw-h-[112px]" (click)="checkout()">
        <div class="tw-bg-teal-600 tw-h-12 tw-rounded-3 tw-text-white tw-flex tw-justify-center tw-items-center tw-relative">
          <div class="tw-text-400 tw-space-x-1 tw-items-center" [class.tw-ml-14]="discount > 5">
            <span class="tw-capitalize" i18n="@@ClickToPay">Click to Pay</span>
            <del>₹{{totalMRP}}</del>
            <span class="tw-text-500 tw-font-bold">₹{{totalSP}}</span>
          </div>
          <div *ngIf="discount && discount > 5" class="tw-absolute tw-left-3 tw-top-[-10px] tw-flex  tw-items-center tw-justify-center">
            <div class="tw-relative">
              <img class="tw-h-[73px] tw-aspect-square tw-text-orange-400 tw-bg-transparent tw-rounded-full tw-shrink-0"
                   src="/assets/icons/discountBg.svg" alt="discount percentage off bg"/>
              <h1 class="tw-absolute tw-inset-0 tw-rounded-full tw-break-all tw-flex tw-flex-col tw-justify-center tw-items-center tw-leading-none tw-text-orange-500 tw-font-bold tw-text-500 -tw-translate-y-0.5">
                <span>{{discount}}%</span>
                <span class="tw-font-medium tw-font-body" i18n="@@off">Off</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<pop-up *ngIf="isDiscontinued"
        [class]="'bdr-rad-10 p15 popup-ht-unset'"
        [show]="isDiscontinued"
        [triggerDismiss]="true"
        (close)="closePopup()">
    <discontinued-popup [oldProductData]="oldProduct"
                        [newProductData]="newProduct"
                        (changeProduct)="changeProduct($event)"
                        (articleOpen)="openArticle($event)"></discontinued-popup>
</pop-up>
